const data = [
  {
    id: 2,
    title: "OnlineTaom",
    image: "img/project/project-2.png",
    category: "mobile",
    details: "Creative Agency",
  },
  {
    id: 3,
    title: "PTU",
    image: "img/project/project-3.png",
    category: "web",
    details: "Mobile Crypto Wallet",
  },
  {
    id: 6,
    title: "SMP",
    image: "img/project/project-6.png",
    category: "web",
    details: "Low Poly Base Mesh",
  },
  {
    id: 5,
    title: "SetBot",
    image: "img/project/project-5.png",
    category: "bot",
    details: "Art Deco Cocktails",
  },
  {
    id: 4,
    title: "Qadaa",
    image: "img/project/project-4.png",
    category: "mobile",
    details: "E-Shop Ecommerce",
  },
  {
    id: 7,
    title: "Karvon.me",
    image: "img/project/project-1.png",
    category: "design",
    details: "Desktop Mockup",
  },
];

export const services = [{ id: 1, icon: "", title: "" }];

export default data;
