import React from "react";
import SingleTeamCard from "./SingleTeamCard";
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);
function TeamWrap(props) {
  const teamSlider = {
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    loop: true,
    slidesPerView: 3,
    spaceBetween: 30,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  };
  return (
    <>
      <section className="our-team sec-mar">
        <div className="container">
          <div
            className="title-wrap  wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className={`${"sec-title"} ${props.white}`}>
              <span>Our Team</span>
              <h2>Meet Our Team</h2>
              <p>
                Quality, dedicated talent is one of your most important
                priorities.
              </p>
            </div>
          </div>
          <Swiper {...teamSlider} className="swiper team-slider">
            <div className="swiper-wrapper">
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <SingleTeamCard
                  image={"/img/team/team-1.jpg"}
                  name="Bakhrom Botirov"
                  title="Solution Architect"
                />
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <SingleTeamCard
                  image={"/img/team/team-2.jpg"}
                  name="Shakhboz Alimov"
                  title="DevOps Engineer"
                />
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <SingleTeamCard
                  image={"/img/team/team-3.jpg"}
                  name="Abdubori Abduvakhobov"
                  title="Frontend Engineer"
                />
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <SingleTeamCard
                  image={"/img/team/team-4.jpg"}
                  name="Abdullokh Nigmatullaev "
                  title="Frontend Engineer"
                />
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <SingleTeamCard
                  image={"/img/team/team-5.jpg"}
                  name="Tokhir Anvakhujaev"
                  title="Backend Engineer"
                />
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <SingleTeamCard
                  image={"/img/team/team-6.jpg"}
                  name="Abbos Alimov"
                  title="Backend Engineer"
                />
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <SingleTeamCard
                  image={"/img/team/team-7.jpg"}
                  name="Dilmurod Rakhimov"
                  title="Mobile App Engineer & UX/UI Designer"
                />
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <SingleTeamCard
                  image={"/img/team/team-8.jpg"}
                  name="Sardor Botirov"
                  title="Software Engineer"
                />
              </SwiperSlide>
            </div>
            <div className="swiper-pagination" />
          </Swiper>
        </div>
      </section>
    </>
  );
}

export default TeamWrap;
