import React from "react";

const SetLogo = ({ color = "#FFFFFF" }) => {
  return (
    <h1>
      <span style={{ color: "#76DAB4" }}>{"{"}</span>
      <span style={{ color }}>
        {/* <span style={{ color: "#76DAB4" }}>S</span>oftware
      <span style={{ color: "#76DAB4" }}>E</span>xpress
      <span style={{ color: "#76DAB4" }}>T</span>eam */}
        set
      </span>
      <span style={{ color: "#76DAB4" }}>{"}"}</span>
    </h1>
  );
};

export default SetLogo;
